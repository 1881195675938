import "../styles/Button.css";

/**
 * Button component
 * @param {object} props - props object expecting variant, style, text and action
 * @returns JSX for Button component
 */

const Button = ({ variant, style, text, action, className }) => {
  // variant = fill or outline
  return (
    <button
      className={`custom-btn custom-btn--${variant} ${className}`}
      style={style}
      onClick={action}
    >
      {text}
    </button>
  );
};

export default Button;
