import React from "react";
import { useState } from "react";
import expand from "../images/expand.svg";
import rest from "../images/rest.png";
import "../styles/CustomAccordion.css";

const CustomAccordion = ({ data, className, style }) => {
  const [isActive, setIsActive] = useState({ status: false, currentId: null });

  return (
    <div className={`question-section ${className}`} style={style}>
      <div className="question-right">
        {data.map((el, idx) => {
          return (
            <div className="question-dropdown" key={idx}>
              <div
                className="question-drop"
                onClick={(e) =>
                  setIsActive((prev) => {
                    return { status: true, currentId: prev.currentId !== el.id ? el.id : null };
                  })
                }
              >
                <div className="question-text">{el.title}</div>
                <div className="question-icon">
                  {isActive.status && el.id === isActive.currentId ? (
                    <img src={rest} alt="" className="rest" />
                  ) : (
                    <img src={expand} alt="" className="expand" />
                  )}
                </div>
              </div>
              <div className={`dropdown-span ${isActive.status && el.id === isActive.currentId ? "dropdown--active" : null}`}>{el.body}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomAccordion;
