import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Navigation from "../components/Navigation";
import AccountManager from "../pages/AccountManager";
import Footer from "../components/Footer";

export const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AccountManager />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};
