import "../styles/CustomCard.css";

const CustomCard = ({ icon, heading, body, className, style }) => {
  return (
    <div className={`custom-card ${className}`} style={style}>
      <img className="custom-card__icon" src={icon} alt="icon" />
      <h3 className="custom-card__header">{heading}</h3>
      <p className="custom-card__body">{body}</p>
    </div>
  );
};

export default CustomCard;
