import { Link, NavLink } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

// Impporting images
import logo from "../images/omnix-logo.svg";

const Navigation = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="transparent" variant="light" className="navigation">
      <div className="container">
        <Navbar.Brand>
          <Link to="/" className="navbar-brand">
            <img className="nav__logo" src={logo} alt="omnix" />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <div className="navbar-nav mx-auto"></div>

          <Nav className="navbar__btns">
            <NavLink to="https://omnixbms.com" className="nav-link px-4 btn__login">
              Main Page
            </NavLink>
            <NavLink to="http://portal.omnixbms.org" className="nav-link px-4 btn__login">
              Log in
            </NavLink>
            <NavLink to="http://portal.omnixbms.org/#/registration" id="navbar__btn" className="nav-link btn__signup">
              Sign up
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Navigation;
