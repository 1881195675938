import "../styles/SectionHeading.css";

const SectionHeading = ({ heading, text, style, className, description }) => {
  return (
    <div className={`section-heading ${className}`} style={style}>
      {text && (
        <div className="section-heading__inner">
          <span className="section-heading__line"></span>
          <span className="section-heading__text">{text}</span>
        </div>
      )}
      <h2 className="section-heading__head">{heading}</h2>
      {description && <p className="section-heading__description">{description}</p>}
    </div>
  );
};

export default SectionHeading;
