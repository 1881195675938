import React from "react";
import fb from "../images/fb.png";
import insta from "../images/insta.png";
import ln from "../images/ln.png";
import logo from "../images/logo.png";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <section className="footer-section">
      <div className="footer-container">
        <div className="footer-omnix">
          <div className="footer-logo">
            <img src={logo} alt="" className="footer-img" />
          </div>

          <div className="footer-text">
            OmnixBMS provides simplified business processes and financial management platform for micro, small and medium enterprise.
          </div>
          <div className="footer-icons">
            <a href="https://www.facebook.com/omnixbms/">
              <img src={fb} alt="omnix facebook" className="foot-icon" />
            </a>
            <a href="https://www.instagram.com/omnixbms/">
              <img src={insta} alt="omnix instagram" className="foot-icon" />
            </a>
            <a href="https://www.linkedin.com/company/omnix-bms/">
              <img src={ln} alt="omnix linkedin" className="foot-icon" />
            </a>
          </div>
        </div>
        <div className="footer-lists">
          <div className="footer-header">Company</div>
          <div className="footer-items">
            <div className="items">
              <a href="https://omnixbms.com/products">Product</a>
            </div>
            <div className="items">
              <a href="https://omnixbms.com/products">Account Manager Login</a>
            </div>
          </div>
        </div>
        <div className="footer-lists">
          <div className="footer-header">Downloads</div>
          <div className="footer-items">
            <div className="items">
              <a href="https://omnixbms.com/download">Desktop</a>
            </div>
            <div className="items">
              <a href="https://bit.ly/omnixapp_store">Apple Store</a>
            </div>
            <div className="items">
              <a href="https://bit.ly/omnixapp_playstore">Playstore</a>
            </div>
          </div>
        </div>
        <div className="footer-lists">
          <div className="footer-header">Useful Links</div>
          <div className="footer-items">
            <div className="items">
              <a href="https://omnixbms.com">Main Page</a>
            </div>
            <div className="items">
              <a href="https://omnixbms.com/enquiry">Contact</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
