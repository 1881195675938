// import style
import "../styles/pages/AccountManager.css";

// import components
import Button from "../components/Button";
import SectionHeading from "../components/SectionHeading";
import CustomCard from "../components/CustomCard";

// import images
import heroIllustration from "../images/account_manager_hero.png";
import { programBenefits, joiningInfo, faqs } from "../data/accountManager";
import CustomAccordion from "../components/CustomAccordion";
import Navigation from "../components/Navigation";
// import { useNavigate } from "react-router-dom";

const AccountManager = () => {
  const handleGettingStarted = () => {
    window.location.href = "http://portal.omnixbms.org/#/registration";
  };

  return (
    <main className="manager">
      <section className="manager__hero">
        <div className="hero__gradient .hero__gradient">&nbsp;</div>
        <div className="hero__morphism container-fluid">
          <Navigation />
          <div className="d-flex align-items-center">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h1>Become an Account Manager/ Software Distributor</h1>
                  <p className="my-5">
                    Join our team of Software distributors & account managers to earn big on commission and other exclusive rewards T&C applied.
                  </p>
                  <Button variant="fill" text="Get Started" style={{ paddingLeft: "60px", paddingRight: "60px" }} action={handleGettingStarted} />
                </div>

                <div className="col-lg-6 d-none d-lg-block">
                  <img className="img-fluid" src={heroIllustration} alt="illustration" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="percent container-fluid">
        <div className="container">
          <div className="percent__container">
            <SectionHeading style={{ maxWidth: "805px" }} text="UNLOCK BIG EARNINGS" heading="Maximize your earnings on referral" />

            <div className="row">
              <div className="col-md">
                <div className="percent__card">
                  <h2 className="percent__header">60%</h2>
                  <p className="percent__body">Get 60% on onboarding cost for every customer you refer.</p>
                </div>
              </div>
              <div className="col-md">
                <div className="percent__card">
                  <h2 className="percent__header">30%</h2>
                  <p className="percent__body"> You also get up to 30% commision on subscription for every new customer you refer for 5 years</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="program-benefits container-fluid">
        <div className="container">
          <SectionHeading text="PROGRAM BENEFITS" heading="Why partner with Omnix?" />

          <div className="row">
            {programBenefits.map((el, idx) => (
              <div className="col-lg-6" key={idx}>
                <CustomCard icon={el.icon} heading={el.heading} body={el.body} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="steps container-fluid">
        <div className="container">
          <SectionHeading
            style={{ maxWidth: "100%" }}
            text="STEPS"
            heading="How to Join"
            description="It only takes a few steps to become an Omnix Software Distributor / Account Manager"
          />

          {joiningInfo.map((info, idx) => {
            return (
              <div className="d-flex mb-3" key={idx}>
                <div>
                  <div className="steps__icon d-flex justify-content-center align-items-center">{info.icon}</div>
                </div>

                <div>
                  <h3 className="steps__heading">{info.heading}</h3>
                  {info.list && (
                    <ul className="steps__list">
                      {info.list.map((item) => {
                        return <li key={item}>{item}</li>;
                      })}
                    </ul>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <section className="call-to-action container-fluid">
        <div className="container">
          <div className="call-to-action__box">
            <h3 className="call-to-action__heading">Join our Program today to start earning big on rewards</h3>
            {/* <p className="call-to-action__body">
              Lorem ipsum dolor sit amet consectetur. Molestie placerat tempus ultrices feugiat accumsan egestas.
            </p> */}
            <Button
              variant="fill"
              text="Get Started"
              style={{ paddingLeft: "60px", paddingRight: "60px" }}
              className="call-to-action__btn"
              action={handleGettingStarted}
            />
          </div>
        </div>
      </section>

      <section className="faq container-fluid">
        <div className="container">
          <SectionHeading style={{ maxWidth: "620px" }} heading="Frequently Asked Questions" />

          <CustomAccordion data={faqs} className="faq__questions" />
        </div>
      </section>
    </main>
  );
};

export default AccountManager;
