// Importing images
import iconAccess from "../images/icon_access.svg";
import iconReward from "../images/icon_rewards.svg";
import iconCommission from "../images/icon_commission.svg";
import iconInsight from "../images/icon_insight.svg";

export const programBenefits = [
  {
    icon: iconReward,
    heading: "Exclusive Rewards",
    body: "The Omnix Software Reseller Programme offers several benefits for businesses looking to expand their reach and gain access to a wider market to earn robust income while helping other businesses optimise their operations.",
  },
  {
    icon: iconCommission,
    heading: "Earn Big on Comissions",
    body: "As an omnix software distributor, you have the opportunity to earn high commissions on each sale you make, as well as recurring commissions from returning customers. With the Omnix brand backing your efforts, you will also have the ability to gain more visibility and reach a wider market.",
  },
  {
    icon: iconInsight,
    heading: "Build Business Awareness",
    body: "You can leverage the well-known and trusted Omnix brand to increase visibility for your business. Offering customised and integrated solutions, as well as exceptional customer support, will also help you stand out. By joining our Partner Ecosystem, you will have the chance to network with other like-minded individuals.",
  },
  {
    icon: iconAccess,
    heading: "Get Insider Access",
    body: "You can leverage the well-known and trusted Omnix brand to increase visibility for your business. Offering customised and integrated solutions, as well as exceptional customer support, will also help you stand out. By joining our Partner Ecosystem, you will have the chance to network with other like-minded individuals.",
  },
];

export const joiningInfo = [
  { icon: 1, heading: "Sign up as an agent", list: null },
  { icon: 2, heading: "Review offers and agreement", list: null },
  { icon: 3, heading: "Create account on the omnix account manager’s portal", list: null },
  { icon: 4, heading: "Update your profile", list: ["Bank account detail to receive your payment.", "Upload your passport photograph and ID card."] },
  { icon: 5, heading: "Verification: automatically with your NIN, BVN or registered phone number", list: null },
  {
    icon: 6,
    heading: "Copy and Save your referrer code/ID",
    list: [
      "Copy your unique (6 digits number) referrer code/ID and save at the ones on omnix mobile app or save somewhere.",
      "Download the Omnix mobile app on Playstore or iOS and register.",
      "Navigate to the Refer a friend and Account manager menu within the app and save you code at ones.",
    ],
  },
  { icon: 7, heading: "Training", list: null },
  {
    icon: 8,
    heading: "Done",
    list: [
      "Congratulations you can now begin to use your Unique refferer code to set-up your customers. You can also promote or share your unique link on your social handles and other business community's.",
    ],
  },
];

export const faqs = [
  {
    id: 1,
    title: "How does the Omnix Account Manager's program work?",
    body: "The Omnix Account Program rewards you with 60% onboarding and up to 30% commission for every new paying customers. You will be able to monitor your progress on the agent portal and view the total number of paid subscribers you have referred.",
  },
  {
    id: 2,
    title: "How do I get my referral link to start earning?",
    body: "Once you have register and have been verified, you will get an approval Status as an affiliate and will automatically access your unique referral Id, which you can then share as link within or outside of the omnix app to any platform (social media, personal emails etc).",
  },
  {
    id: 3,
    title: "How does my referral link work?",
    body: "Once download is made from your referral link. The system automatically generates your unique referral link and fills it into the referral code section during the signup process of a new user. The code must not be removed while completing the sign-up process because it is the only way to authenticate who has referrered.",
  },
  {
    id: 4,
    title: "Where do I share my referral link?",
    body: "Share your referral link within the omnix mobile app by navigating to the Invite a friend menu/Affiliate refer and earn section. Once you have saved your code that you copied from the agents portal, begin to share your unique referral link which gives you opportunity to earn when a new customer pays. You are able to track your earning and easily access your cash immediately. You will Increase your potential cash earning the more you share with friends and followers via your social networks (Facebook, Instagram, WhatsApp, telegram, twitter, LinkedIn, online groups, personalised email, blogs, etc).",
  },
  {
    id: 5,
    title: "Are there promotional content that can help me?",
    body: "You will be added to our affiliates community group on Telegram and will be granted access to omnix business community group on facebook where you will have access to tips, daily posts and other assets of the company.",
  },
  {
    id: 6,
    title: "Where do I get support and help?",
    body: "Kindly email, or send chat to WhatsApp for help on; Email: support@omnixbms.com Phone: 08066242995 or 09060288730",
  },
];
